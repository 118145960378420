export type TargetEnv = 'development' | 'staging' | 'production';

// Used for dynamically setting public config from .env for the client to use
export interface PublicConfig {
  targetEnv: TargetEnv;
  studioUrl: string;
  webAppBaseUrl: string;
  userMediaBaseUrl: string;
  apiBaseUrl: string;
  adminApiBaseUrl: string;
  subdomainAccessibleCookieDomain: string;
  publicPath: string;
  sentryBrowserDsn: string;
  segmentWriteKey: string;
  ghostApiUrl: string;
  ghostApiKey: string;
  ldClientSideId: string;
  communicationsDisabled: boolean;
}

// Default public config, override with .env variables
export const defaultConfigs: { [P in TargetEnv]: PublicConfig } = {
  development: {
    targetEnv: 'development',
    studioUrl: 'http://localhost:3000',
    webAppBaseUrl: 'http://localhost:8080',
    userMediaBaseUrl: 'https://goosetest2.s3.amazonaws.com',
    apiBaseUrl: 'http://localhost:3001/studio',
    adminApiBaseUrl: 'http://localhost:8000/api/admin',
    subdomainAccessibleCookieDomain: '',
    publicPath: 'http://0.0.0.0:9001/',
    sentryBrowserDsn: '',
    segmentWriteKey: '',
    ghostApiUrl: 'goosechase.ghost.io',
    ghostApiKey: '',
    ldClientSideId: '',
    communicationsDisabled: false,
  },
  staging: {
    targetEnv: 'staging',
    studioUrl: 'https://studio.goosechase.dev',
    webAppBaseUrl: 'https://stagify.goosechase.com',
    userMediaBaseUrl: 'https://goosev3.s3.amazonaws.com',
    apiBaseUrl: 'https://stagify.goosechase.com/api/web',
    adminApiBaseUrl: 'https://stagify.goosechase.com/api/admin',
    subdomainAccessibleCookieDomain: '.goosechase.dev',
    publicPath: '/',
    sentryBrowserDsn: '',
    segmentWriteKey: '',
    ghostApiUrl: 'goosechase.ghost.io',
    ghostApiKey: '',
    ldClientSideId: '',
    communicationsDisabled: false,
  },
  production: {
    targetEnv: 'production',
    studioUrl: 'https://studio.goosechase.com',
    webAppBaseUrl: 'https://www.goosechase.com',
    userMediaBaseUrl: 'https://goosechase.s3.amazonaws.com',
    apiBaseUrl: 'https://www.goosechase.com/api/web',
    adminApiBaseUrl: 'https://www.goosechase.com/api/admin',
    subdomainAccessibleCookieDomain: '.goosechase.com',
    publicPath: '/',
    sentryBrowserDsn: '',
    segmentWriteKey: '',
    ghostApiUrl: 'goosechase.ghost.io',
    ghostApiKey: '',
    ldClientSideId: '',
    communicationsDisabled: false,
  },
};

export let appConfig: PublicConfig = defaultConfigs.development;

export function setConfig(config: PublicConfig): void {
  appConfig = config;
}
