import { isServer } from './util';

export interface AppConstants {
  BASE_URL: string;
  CAREERS_URL: string;
  LEGAL_RESOURCES_URL: string;
  HOME_USE_CASE_INITIAL_INDEX: number;
  SERVER_COOKIE_STRING: string;
  HAS_DJANGO_SESSION_COOKIE: boolean; // indicates whether a sessionid httpOnly cookie exists, meaning user could be logged in (must verify)
  USER_COUNTRY_CODE?: string;
  USER_MARKETING_TRACKING_DISABLED?: boolean;
  /** Duration in milliseconds to debounce inputs before fetching new data or other expensive operations.
   * See: https://humanbenchmark.com/tests/reactiontime/statistics
   */
  INPUT_DEBOUNCE_MS: number;
}

// Some constants may be set dynamically from the server
const appConstants: AppConstants = {
  BASE_URL: 'https://www.goosechase.com',
  CAREERS_URL: 'https://apply.workable.com/goosechase/',
  LEGAL_RESOURCES_URL: 'https://goosechase.notion.site/Legal-Resource-Hub-b179b695b90447d3ac8a897469e827ad',
  HOME_USE_CASE_INITIAL_INDEX: 0,
  SERVER_COOKIE_STRING: '',
  HAS_DJANGO_SESSION_COOKIE: false,
  INPUT_DEBOUNCE_MS: 250,
};

// Used from the server to set constants before server rendering
export function updateConstants(newConstants: Partial<AppConstants>) {
  Object.keys(newConstants).forEach((key) => {
    const _key = key as keyof AppConstants;
    const val = newConstants[_key];
    if (_key && val !== undefined) {
      // Typescript changed to not allow this operation, but the workaround is just more confusing https://github.com/microsoft/TypeScript/wiki/Breaking-Changes#fixes-to-unsound-writes-to-indexed-access-types
      (appConstants[_key] as any) = val;
    }
  });
}

// Used at the client entry point to set constants sent from the server
export function loadConstantsFromWindow() {
  if (isServer()) {
    return;
  }

  const preloadedConstants = window.__GC_CONSTANTS__;
  if (!preloadedConstants) {
    return;
  }

  updateConstants(preloadedConstants);

  delete window.__GC_CONSTANTS__;
}

export default appConstants;
